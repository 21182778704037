import React, { useEffect, useState } from "react";
import Side_bar from "./Side_bar";
import NavBar from "./../NavBar";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  getListOfWithdraw,
  getOverview,
  withDraw,
} from "./actions/investActions";
import Selector from "../Common/selector";
import Input from "../Common/Input";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import Modal from "react-responsive-modal";
import { Button } from "react-bootstrap";
import MUIDataTable from "mui-datatables";
import { FaCheck } from "react-icons/fa";
import moment from 'moment';

const WithdrawFunds_2 = () => {
  const schema = yup.object().shape({
    address: yup.string().required("Wallet Address is a required field."),
    amount: yup
      .number()
      .positive("Amount should be a positive value.")
      .moreThan(0, "Amount should be greater than 0.")
      .test(
        "is-valid-amount",
        "Amount is greater than available balance",
        function (value) {
          const commissionType = this.parent.commissionType;

          if (commissionType === "Referral Commission") {
            return value <= overview?.refral_earned;
          }

          if (commissionType === "Interest Commission") {
            return value <= overview?.interest_earned;
          }
          if (commissionType === "Group Bonus") {
            return value <= overview?.group_bonus;
          }

          return false;
        }
      )
      .required("Amount is a required field."),
    network: yup.string().required("Network is a required field."),
    commissionType: yup
      .string()
      .required("Commission type is a required field."),
  });
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const option = [{ label: "TRC", value: "TRC" }];
  const typeOption = [
    { label: "ROI", value: "Interest Commission" },
    { label: "Referral Bonus", value: "Referral Commission" },
  ];

  const gpTypeOption = [
    { label: "ROI", value: "Interest Commission" },
    { label: "Referral Bonus", value: "Referral Commission" },
    { label: "Group Bonus", value: "Group Bonus" },
  ];

  const dispatch = useDispatch();
  const history = useHistory();
  const { loading } = useSelector((state) => state.loading);
  const { backend_errors } = useSelector((state) => state.error);
  const { overview } = useSelector((state) => state?.overview);
  const { withdraw } = useSelector((state) => state?.history);
  const { user } = useSelector((state) => state?.auth.user);
  const [error, setError] = useState(null);
  const [typeValue, setTypeValue] = useState(undefined);
  const [withdrawls, setWithdrawls] = useState([]);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    values: {
      amount: "",
      address: "",
      network: "",
      commissionType: "",
    },
    resolver: yupResolver(schema),
  });
  const { country } = watch();

  useEffect(() => {
    dispatch(getListOfWithdraw());
  }, []);

  useEffect(() => {
    if (withdraw?.length > 0) {
      setWithdrawls(
        withdraw?.map((item) => {
          return {
            ...item,
            network: item?.network,
            amount: item?.amount,
            type: item?.user_id?.type,
            status: item?.status,
            Withdrawal: item?.status === "Approved" && <FaCheck size={24} />,
            withdrawl_date: item?.status === "Approved" ? item?.updatedAt : null,
          };
        })
      );
    }
  }, [withdraw]);
  

  useEffect(() => {
    setTypeValue(history?.location?.state?.details?.user_id?.type);
    setValue("amount", history?.location?.state?.details?.amount);
  }, [history]);

  useEffect(() => {
    if (backend_errors) {
      setError(backend_errors);
    }
  }, [backend_errors]);

  useEffect(() => {
    dispatch(getOverview());
  }, []);

  const _withDraw = (data) => {
    setError(null);
    data["type"] = history?.location?.state?.details?.user_id.type
      ? history?.location?.state?.details?.user_id?.type
      : "Withdrawal";
    dispatch(withDraw(data, history, setShowModal));
    setShowModal(true);
  };

  const { t, i18n } = useTranslation();

  const columns = [
    {
      name: "network",
      label: "Network",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "type",
      label: "Type",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Withdrawal",
      label: "",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "withdrawl_date",
      label: "Withdrawal Date",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? moment(value).format("YYYY-MM-DD H:MM:SS") : null;
        },
      },
    },
  ];

  const getGroupBonus = () => {
    if (Object.keys(overview).includes("group_bonus")) {
      return (
        <div style={{ width: "auto" }}>
          <p className="light-txts text-center mb-1">{t("Group Bonus")}</p>
          <div className="both-shadow" style={{ padding: "0px 1rem" }}>
            <p className="text-center m-0">
              {loading ? (
                <Skeleton />
              ) : overview?.group_bonus ? (
                `USDT ${overview?.group_bonus}`
              ) : (
                0
              )}
            </p>
          </div>
        </div>
      );
    }
  };

  return (
    <form onSubmit={handleSubmit((data) => _withDraw(data))}>
      <NavBar />
      <div className="withdraw_funds_2">
        <Side_bar />
        <div className="content_bar">
          <div className="page-content">
            <h1 className="text-center">{t("Withdraw Funds")}</h1>
            <div className="outer-card">
              <div className="inner-card">
                <div className="justify-content-center d-flex mb-3 gap-5 align-items-center">
                  <div style={{ width: "auto" }}>
                    <p className="light-txts text-center mb-1">
                      {t("Referral Earned")}
                    </p>
                    <div
                      className="both-shadow m-auto"
                      style={{ padding: "0px 1rem" }}
                    >
                      <p className="text-center mb-0">
                        {loading ? (
                          <Skeleton />
                        ) : overview?.refral_earned ? (
                          `USDT ${overview?.refral_earned}`
                        ) : (
                          0
                        )}
                      </p>
                    </div>
                  </div>
                  <div style={{ width: "auto" }}>
                    <p className="light-txts text-center mb-1">
                      {t("Interest Earned")}
                    </p>
                    <div
                      className="both-shadow"
                      style={{ padding: "0px 1rem" }}
                    >
                      <p className="text-center m-0">
                        {loading ? (
                          <Skeleton />
                        ) : overview?.interest_earned ? (
                          `USDT ${overview?.interest_earned}`
                        ) : (
                          0
                        )}
                      </p>
                    </div>
                  </div>
                  {getGroupBonus()}
                </div>
                <div className="mt-2 mb-2">
                  <Selector
                    value={typeValue && typeValue}
                    label="Type:"
                    options={
                      overview?.group_bonus !== undefined
                        ? gpTypeOption
                        : typeOption
                    }
                    errors={errors?.commissionType?.message}
                    defaultOption={"Select Commission"}
                    register={register}
                    name="commissionType"
                  />
                </div>
                <Input
                  label="Amount:"
                  errors={errors?.amount?.message}
                  type="text"
                  name="amount"
                  className={true}
                  register={register}
                />
                <Input
                  label="Wallet Address:"
                  errors={errors?.address?.message}
                  type="text"
                  name="address"
                  className={true}
                  register={register}
                />
                <div className="mt-2 mb-2">
                  <Selector
                    label="Network:"
                    options={option}
                    errors={errors?.network?.message}
                    defaultOption={"Select Network"}
                    register={register}
                    name="network"
                    value={"TRC"}
                  />
                </div>

                {error && (
                  <span
                    className="fields"
                    style={{ color: "red", marginTop: "10px" }}
                  >
                    {error && error?.message}
                  </span>
                )}

                <div className="d-flex justify-content-around my-4">
                  <button type="submit">{t("Withdraw")}</button>
                </div>

                <Modal open={showModal} onClose={handleCloseModal} center>
                  <h2>Withdrawal Request</h2>
                  <p>
                    Your withdrawal request will be processed within 7 working
                    days.
                  </p>
                  <Button variant="secondary" onClick={handleCloseModal}>
                    OK
                  </Button>
                </Modal>
              </div>
            </div>
            <div className="trans-content interest-earned-btn">
              <h1>{t("Withdraw History")}</h1>
              <MUIDataTable
                title={"Withdraw History"}
                data={withdrawls}
                columns={columns}
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default WithdrawFunds_2;
