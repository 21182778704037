import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Menu } from "@material-ui/icons";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import SATIVA_Logo from "../img/SATIVA-Logo.png";
import mac from "../img/mac.png";
import agro2 from "../img/agro2.png";
import united_kingdom from "../img/united-kingdom.png";
import chinese from "../img/chinese.png";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "./UserPanel/actions/loginActions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";

const NavBar = () => {
  const dispatch = useDispatch();
  const { isAuthenticated, lang } = useSelector((state) => state?.auth);
  const history = useHistory();

  const _logout = () => {
    dispatch(logout(history));
  };

  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    if (lng) {
      i18n.changeLanguage("english".slice(0, 2));
      dispatch({ type: "SET_CURRENT_LANG", payload: !lang });
    } else {
      i18n.changeLanguage("chinese".slice(0, 2));
      dispatch({ type: "SET_CURRENT_LANG", payload: !lang });
    }
  };

  return (
    <Navbar bg="" expand="md">
      <Container fluid>
        <Link
          className="navbar-brand"
          to={{ pathname: "https://maconhaholding.com/" }}
          target="_blank"
        >
          <div className="d-flex align-items-center logos">
            <img src={mac} alt="" />
           
          </div>
        </Link>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <div className="d-flex flex-row align-items-center d-md-none gap-3">
          <div
            className="btn lang_btn m-0"
            onClick={() => changeLanguage(!lang)}
          >
            {lang === true && (
              <img
                onClick={() => changeLanguage(!lang)}
                src={united_kingdom}
                alt="united kingdom"
                width="32px"
                style={{ borderRadius: "50%", marginRight: "18px" }}
              />
            )}
            {lang === false && (
              <img
                onClick={() => changeLanguage(!lang)}
                src={chinese}
                alt="chinese"
                width="32px"
                style={{ borderRadius: "50%", marginLeft: "18px" }}
              />
            )}
          </div>
          {isAuthenticated && (
            <div className="dropdown mx-2">
              <span
                data-bs-toggle="dropdown"
                className="p-2 bg-body-secondary position-relative"
                style={{ borderRadius: "10px", cursor: "pointer" }}
              >
                <Menu viewBox="0 0 84px 84px" color="#00000040" />
              </span>
              <ul
                className="dropdown-menu position-absolute"
                style={{ right: "0px", left: "auto" }}
              >
                <li>
                  <Link
                    to="/user/investment-overview"
                    style={{ borderRadius: "16px 16px 0 0" }}
                  >
                    {t("Investment Overview")}
                  </Link>
                </li>
                <li>
                  <Link to="/user/invest">{t("Invest")}</Link>
                </li>
                <li>
                  <Link to="/user/incomes">{t("Incomes")}</Link>
                </li>
                <li>
                  <Link to="/user/withdraw-funds-2">{t("Withdrawal")}</Link>
                </li>
                <li>
                  <Link
                    to="/user/my-profile"
                    style={{ borderRadius: "16px 16px 0 0" }}
                  >
                    {t("My profile")}
                  </Link>
                </li>
                <li>
                  <Link to="/user/login" onClick={() => _logout()}>
                    {t("Log out")}
                  </Link>
                </li>
                <li>
                  <Link
                    style={{ borderRadius: "0 0 16px 16px" }}
                    to={{ pathname: "https://maconhaholding.com/" }}
                    target="_blank"
                  >
                    {t("Home")}{" "}
                  </Link>
                </li>
              </ul>
            </div>
          )}
        </div>
        <Navbar.Collapse className="ml-auto">
          <Nav className="my-2 my-lg-0 align-items-center align-items-md-start">
            {!isAuthenticated && (
              <Link className="nav-link" to="/user/login">
                {t("LOGIN")}
              </Link>
            )}
          </Nav>
          <div className="d-md-flex align-items-center d-none d-md-block">
            <div className="d-flex align-items-center">
              <div
                className="btn lang_btn"
                onClick={() => changeLanguage(!lang)}
              >
                {lang === true && (
                  <img
                    onClick={() => changeLanguage(!lang)}
                    src={united_kingdom}
                    alt="united kingdom"
                    width="32px"
                    style={{ borderRadius: "50%", marginRight: "18px" }}
                  />
                )}
                {lang === false && (
                  <img
                    onClick={() => changeLanguage(!lang)}
                    src={chinese}
                    alt="chinese"
                    width="32px"
                    style={{ borderRadius: "50%", marginLeft: "18px" }}
                  />
                )}
              </div>
            </div>
            {isAuthenticated && (
              <div className="dropdown mx-2">
                <span
                  data-bs-toggle="dropdown"
                  className=" p-2 bg-body-secondary position-relative"
                  style={{ borderRadius: "10px", cursor: "pointer" }}
                >
                  <Menu viewBox="0 0 84px 84px" color="#00000040" />
                </span>
                <ul
                  className="dropdown-menu position-absolute"
                  style={{ right: "0px", left: "auto" }}
                >
                  <li>
                    <Link
                      style={{ borderRadius: "16px 16px 0 0" }}
                      to="/user/my-profile"
                    >
                      {t("My profile")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/user/login" onClick={() => _logout()}>
                      {t("Log out")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      style={{ borderRadius: "0 0 16px 16px" }}
                      to={{ pathname: "https://maconhaholding.com/" }}
                      target="_blank"
                    >
                      {t("Home")}{" "}
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
