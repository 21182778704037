import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { AiOutlineUser } from "react-icons/ai";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import SATIVA_Logo from "../../img/SATIVA-Logo.png";
import mac from "../../img/mac.png";
import agro2 from "../../img/agro2.png";
import united_kingdom from "../../img/united-kingdom.png";
import chinese from "../../img/chinese.png";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

const NavBar = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { lang } = useSelector((state) => state?.auth);
  const changeLanguage = (lng) => {
    if (lng) {
      i18n.changeLanguage("english".slice(0, 2));
      dispatch({ type: "SET_CURRENT_LANG", payload: !lang });
    } else {
      i18n.changeLanguage("chinese".slice(0, 2));
      dispatch({ type: "SET_CURRENT_LANG", payload: !lang });
    }
  };
  return (
    <Navbar className="userPanel_navBar">
      <Container fluid>
        <Link
          className="navbar-brand"
          to={{ pathname: "https://maconhaholding.com/" }}
          target="_blank"
        >
          <div className="d-flex align-items-center logos">
            <img src={mac} alt="" style={{ paddingTop: "10px" }} />
            <div className="d-flex flex-column">
              {/* <img src={agro} alt="" /> */}
              <img className="partner-logo" src={agro2} alt="" />
              <span className="partner-text text-center">
                Our Marketing Arm
              </span>
            </div>
          </div>
        </Link>
        <Navbar.Collapse className="ml-auto p-4">
          <div className="d-flex align-items-center">
            <div className="btn lang_btn" onClick={() => changeLanguage(!lang)}>
              {lang === true && (
                <img
                  onClick={() => changeLanguage(!lang)}
                  src={united_kingdom}
                  alt="united kingdom"
                  width="32px"
                  style={{ borderRadius: "50%", marginRight: "18px" }}
                />
              )}
              {lang === false && (
                <img
                  onClick={() => changeLanguage(!lang)}
                  src={chinese}
                  alt="chinese"
                  width="32px"
                  style={{ borderRadius: "50%", marginLeft: "18px" }}
                />
              )}
            </div>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
export default NavBar;
